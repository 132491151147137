import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/topics';
import { filterRequiredParams } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import Page from 'core/components/Page';
import { PageIndent, SideIndent } from 'core/components/Wrappers';
import PageLoader from 'core/components/Loader/PageLoader';
import FooterAds from 'site/components/Ads/FooterAds';

import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';
import CardMain from 'site/cards/CardMain';
import CardSelection from 'site/cards/CardSelection';
import CardSelectionSimple from 'site/cards/CardSelectionSimple';

import newsQuery from 'site/queries/news';

import Content from './Content';

function MainPage() {
  const results = useRequest({
    queries: [
      topicsQuery({
        list: 'main',
        sort: 'list',
        include: filterRequiredParams([Card2, Card3, Card4, CardMain], 'include'),
        fields: filterRequiredParams([Card2, Card3, Card4, CardMain], 'fields'),
        limit: 15,
        topic_type: '-selection',
        renderError: () => [],
      }),
      topicsQuery({
        limit: 5,
        card: Card3,
        sort: '-views_count',
        renderError: () => [],
      }),
      topicsQuery({
        limit: 1,
        list: 'selection',
        sort: 'list',
        include: 'read_more,' + filterRequiredParams([CardSelection], 'include'),
        fields: filterRequiredParams([CardSelection, CardSelectionSimple], 'fields'),
        renderError: () => [],
      }),
      newsQuery(true),
    ],
  });

  const {
    data: [
      topics,
      popular,
      selection,
      news,
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);


  if (resultsAreLoading) {
    return <PageLoader />;
  }

  return (
    <Page>
      <PageIndent>
        <div style={{ minHeight: 600 }}>
          <Content
            topics={topics}
            popular={popular}
            selection={selection[0]}
            news={news}
          />
        </div>
      </PageIndent>
      <SideIndent>
        <FooterAds contextCount={2} />
      </SideIndent>
    </Page>
  );
}


export default MainPage;
